import React from "react"
import PropTypes from "prop-types"
import TermsOfServiceFormRow from "./form_row/terms_of_service"
import PrivacyPolicyFormRow from "./form_row/privacy_policy"
import CleavePhoneFormRow from "./form_row/cleave_phone"
import InputFormRow from "./form_row/input"
import SelectFormRow from "./form_row/select"
import TextareaFormRow from "./form_row/textarea"
import classNames from "classnames"
import {get, isEmpty} from "lodash"

class Wizard extends React.Component {
  renderField(modelName, field) {
    const data = this.props.data[modelName][field.name]
    let RowComponent = get({
      terms_of_service: TermsOfServiceFormRow,
      privacy_policy: PrivacyPolicyFormRow,
      tel: CleavePhoneFormRow,
      select: SelectFormRow,
      textarea: TextareaFormRow,
    }, field.type, InputFormRow)

    return (
        <RowComponent
            key={field.name}
            className={field.className}
            type={field.type}
            inputName={field.inputName}
            label={field.label}
            options={field.options}
            placeholder={field.placeholder}
            prompt={field.prompt}
            autoComplete={field.autoComplete}
            value={data.value}
            error={data.error}
            onChange={value => this.props.onInputChange(modelName, field.name, value)}
        />
    )
  }

  getCSRF() {
    if (!isEmpty(this.props.csrfParam) && !isEmpty(this.props.csrfToken)) {
      return <input type="hidden" name={this.props.csrfParam} value={this.props.csrfToken}/>
    }
  }

  render() {
    const currentStep = this.props.steps[this.props.step]
    const someTitles = this.props.steps.some(s => s.title)
    return (
        <div className="sw-theme-circles">
          <ul className={classNames("nav nav-tabs step-anchor d-none d-sm-flex", someTitles ? "mb-5" : "mb-3")}>
            {this.props.steps.map((step, i) => (
                <li key={step.key} className={classNames("nav-item", {active: i === this.props.step, done: i < this.props.step})}>
                  <a className="nav-link">
                    <i className={step.icon}/>
                    {step.title && (
                        <p className="bold my-0 desc">
                          {step.title}
                        </p>
                    )}
                  </a>
                </li>
            ))}
          </ul>
          <div className="step-content">
            {currentStep.legend && (
                <h5 className="my-4 b-b pb-2">
                  <i className={classNames("icon", currentStep.icon)}/>
                  {currentStep.legend}
                </h5>
            )}
            <form
                ref={this.props.formRef}
                action={this.props.formAction}
                method={this.props.formMethod}
                acceptCharset="UTF-8">
              <input type="hidden" name="utf8" value="✓"/>
              {this.getCSRF()}
              {this.props.steps.map((step, i) => (
                  <fieldset key={step.key} hidden={this.props.step !== i}>
                    {step.fields.map((field) => this.renderField(step.modelName, field))}
                  </fieldset>
              ))}
            </form>
          </div>

          <div className="btn-toolbar sw-toolbar sw-toolbar-bottom">
            <div className="btn-group mr-2 sw-btn-group" role="group">
              <button
                  className="btn btn-accent btn-rounded btn-prev"
                  disabled={this.props.step === 0}
                  type="button"
                  onClick={this.props.onPreviousStep}>
                {I18n.t("js.company_registration_wizard.previous")}
              </button>
              <button
                  className="btn btn-accent btn-rounded btn-next"
                  type="button"
                  disabled={this.props.loading}
                  onClick={this.props.onNextStep}>
                {this.props.step === this.props.steps.length - 1 ?
                    I18n.t("js.company_registration_wizard.submit") :
                    I18n.t("js.company_registration_wizard.next")}
                {this.props.loading ? (
                    <i className="fa fa-spinner fa-spin ml-2"/>
                ) : (
                    <i className="fas fa-long-arrow-alt-right ml-2"/>
                )}
              </button>
            </div>
          </div>
        </div>
    )
  }
}

Wizard.propTypes = {
  loading: PropTypes.bool,
  formRef: PropTypes.func.isRequired,
  csrfParam: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
  onPreviousStep: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  formAction: PropTypes.string.isRequired,
  formMethod: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        title: PropTypes.string,
        legend: PropTypes.string,
        icon: PropTypes.string,
        modelName: PropTypes.string,
        fields: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              type: PropTypes.string,
              inputName: PropTypes.string,
              label: PropTypes.string,
              placeholder: PropTypes.string,
            })
        ),
      })
  ).isRequired,
  data: PropTypes.objectOf(
      PropTypes.shape({
        value: PropTypes.string,
        error: PropTypes.string,
      })
  ).isRequired,
  onInputChange: PropTypes.func,
}

export default Wizard
