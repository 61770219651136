import React from 'react'
import { merge, get, pick, isEmpty, isArray, flatten, uniq } from "lodash"
import Wizard from "../wizard"

const updateModelErrors = (model, fields, errors) => (
    fields.reduce((newModel, fieldName) => (
        merge(newModel, {[fieldName]: {error: get(errors,fieldName, null)}})
    ), model)
)

export default class Controller extends React.Component {
  constructor(props) {
    super(props)
    if (document.getElementsByName("csrf-param").length === 1) {
      this.csrfParam = document.getElementsByName("csrf-param")[0].content
    }
    if (document.getElementsByName("csrf-token").length === 1) {
      this.csrfToken = document.getElementsByName("csrf-token")[0].content
    }

    this.steps = this.getStepsDefinitions()

    flatten(this.steps).forEach(step => {
      const labelsScope = `simple_form.labels.${step.modelName}`
      const placeholdersScope = `simple_form.placeholders.${step.modelName}`
      step.fields.forEach(field => {
        field.label = I18n.t(`${labelsScope}.${field.name}`);
        field.placeholder = I18n.t(`${placeholdersScope}.${field.name}`);
      })
    })

    this.formElement = null

    this.onInputChange = this.onInputChange.bind(this)
    this.handleNextStep = this.handleNextStep.bind(this)
    this.prevStep = this.prevStep.bind(this)

    this.state = this.getInitialState()
  }

  getStepsDefinitions() {
    throw new Error('You have to implement the method getStepsDefinitions!');
  }

  getFormAction() {
    throw new Error('You have to implement the method getFormAction!');
  }

  getInitialState() {
    throw new Error('You have to implement the method getInitialState!');
  }

  validateRegistration() {
    throw new Error('You have to implement the method validateRegistration!');
  }

  handleNextStep() {
    const step = this.getSteps()[this.state.step];
    const fields = step.fields.map(field => field.name)
    const isLastStep = this.state.step === this.steps.length - 1

    this.setState({loading: true}, () => {
      this.validateFields(step.modelName, fields).then(() => {
        const newModel = updateModelErrors(this.state[step.modelName], fields, {});
        if (isLastStep) {
          this.setState({
            [step.modelName]: newModel,
            loading: false,
          }, () => this.formElement.submit())
        } else {
          this.setState({
            [step.modelName]: newModel,
            step: this.state.step + 1,
            loading: false,
          });
        }
      }, (errors) => {
        this.setState({
          [step.modelName]: updateModelErrors(this.state[step.modelName], fields, errors),
          loading: false,
        })
      })
    })
  }

  prevStep() {
    if (this.state.step !== 0) {
      this.setState({step: this.state.step - 1})
    }
  }

  getSteps() {
    return this.steps
  }

  onInputChange(modelName, fieldName, newValue) {
    this.setState({
      [modelName]: merge({}, this.state[modelName], {[fieldName]: {value: newValue}}),
    })
  }

  validateFields(modelName, fieldNames) {
    return new Promise((resolve, reject) => {
      this.validateRegistration().then(resolve, (errors) => {
        const fieldErrors = pick(errors[modelName], fieldNames);
        if (Object.values(fieldErrors).every(isEmpty)) {
          resolve()
        } else {
          reject(fieldErrors)
        }
      })
    });
  }

  getData() {
    const keys = uniq(flatten(this.steps).map(s => s.modelName))
    return pick(this.state, keys)
  }

  render() {
    return (
        <Wizard
            formAction={this.getFormAction()}
            formMethod="post"
            loading={this.state.loading}
            formRef={function (el) { this.formElement = el }.bind(this)}
            csrfParam={this.csrfParam}
            csrfToken={this.csrfToken}
            steps={this.getSteps()}
            step={this.state.step}
            data={this.getData()}
            onNextStep={this.handleNextStep}
            onPreviousStep={this.prevStep}
            onInputChange={this.onInputChange}
        />
    )
  }
}
