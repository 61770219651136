import React from "react"
import PropTypes from "prop-types"
import {snakeCase, isEmpty} from "lodash"
import classNames from "classnames"

const TextareaFormRow = props => {
  const inputId = snakeCase(props.inputName)
  return (
      <div className="form-group">
        <label className="small bold" htmlFor={inputId}>
          {props.label}
        </label>
        <textarea
            id={inputId}
            className={classNames("form-control", props.className, {"is-invalid": !isEmpty(props.error)})}
            name={props.inputName}
            value={props.value}
            placeholder={props.placeholder}
            rows="5"
            onChange={event => props.onChange(event.target.value)}
        />
        <div className="invalid-feedback">{props.error}</div>
      </div>
  )
}

TextareaFormRow.propTypes = {
  inputName: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
}

export default TextareaFormRow
