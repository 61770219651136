import { merge, get, pick, isEmpty, isArray, flatten } from "lodash"
import Controller from "../wizard/controller"
import { validate } from "./api"

export default class ArchitecturalServicesWizard extends Controller {

  getFormAction() {
    return Routes.architectural_services_sign_up_path()
  }

  getStepsDefinitions() {
    return [
      {
        key: "1",
        icon: "fas fa-map-marker-alt",
        modelName: "architectural_services_data",
        fields: [
          {name: "zip",  type: "text", inputName: "user[data_attributes][zip]", label: null, placeholder: null},
        ],
      },
      {
        key: "2",
        icon: "fas fa-info-circle",
        modelName: "architectural_services_data",
        fields: [
          {name: "description", type: "textarea", inputName: "user[data_attributes][description]", label: null, placeholder: null},
        ],
      },
      {
        key: "3",
        legend: I18n.t("js.architectural_services_wizard.contacts"),
        icon: "fas fa-user",
        modelName: "user",
        fields: [
          {name: "phone",            type: "text",             inputName: "user[phone]",            autoComplete: "tel",          label: null, placeholder: null},
          {name: "email",            type: "email",            inputName: "user[email]",            autoComplete: "email",        label: null, placeholder: null},
          {name: "terms_of_service", type: "terms_of_service", inputName: "user[terms_of_service]", autoComplete: null,           label: null, placeholder: null},
          {name: "privacy_policy",   type: "privacy_policy",   inputName: "user[privacy_policy]",   autoComplete: null,           label: null, placeholder: null},
        ],
      },
    ]
  }

  getInitialState() {
    return {
      user: {
        phone:            { value: "", error: null },
        email:            { value: "", error: null },
        terms_of_service: { value: false, error: null },
        privacy_policy:   { value: false, error: null },
      },
      architectural_services_data: {
        zip:         { value: "", error: null },
        description: { value: "", error: null },
      },
      step: 0,
      loading: false,
    }
  }

  validateRegistration() {
    return validate({
      user: {
        email: this.state.user.email.value,
        phone: this.state.user.phone.value,
        terms_of_service: this.state.user.terms_of_service.value ? "1" : "0",
        privacy_policy: this.state.user.privacy_policy.value ? "1" : "0",
        data_attributes: {
          zip: this.state.architectural_services_data.zip.value,
          description: this.state.architectural_services_data.description.value
        }
      }
    })
  }

}
