import React from "react"
import PropTypes from "prop-types"

const ViewMode = (props) => (
    <div className="offer-info">
      <p className="text-body" style={{whiteSpace: "pre-wrap"}}>{props.details}</p>
      <p className="price-block">{I18n.t("js.offer_view_mode.price")}: <b>{props.price}</b></p>
      <p>
        {props.ability.update &&
          <button
              className="edit-offer btn btn-rounded btn-1 btn-sm accent mr-1"
              onClick={props.onEdit}>
            <i className="fas fa-pen mr-2"/>
            {I18n.t("js.offer_view_mode.edit")}
          </button>
        }
        {props.ability.destroy &&
          <button
              onClick={props.onDelete}
              className="delete-offer btn btn-rounded btn-1 btn-sm mr-1">
            <i className="far fa-trash-alt mr-2"/>
            {I18n.t("js.offer_view_mode.delete")}
          </button>
        }
        {props.ability.win &&
          <button
              onClick={props.onWin}
              className="btn btn-rounded btn-outline-accent btn-sm"
              data-plugin-tooltip=""
              data-toggle="tooltip"
              data-placement="bottom"
              title={I18n.t("js.offer_view_mode.winner_notice")}>
            <i className="far fa-star mr-2"/>
            {I18n.t("js.offer_view_mode.winner")}
          </button>
        }
      </p>
    </div>
)

ViewMode.propTypes = {
  ability: PropTypes.shape({
    update: PropTypes.bool.isRequired,
    destroy: PropTypes.bool.isRequired,
    win: PropTypes.bool.isRequired,
  }).isRequired,
  details: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onWin: PropTypes.func.isRequired,
}

export default ViewMode
