import { merge, get, pick, isEmpty, isArray, flatten, once } from "lodash"
import Controller from "../wizard/controller"
import { validate } from "./api"

const trackInteraction = once(function () {
  if (typeof gtag !== 'undefined') {
    // TODO: move to config
    gtag('event', 'conversion', {'send_to': 'AW-719604066/eHrVCObsscgBEOKSkdcC'})
  }
});

export default class InsuranceServicesWizard extends Controller {

  getFormAction() {
    return Routes.insurance_services_sign_up_path()
  }

  getStepsDefinitions() {
    return [
      {
        key: "1",
        icon: "fas fa-code-branch",
        modelName: "insurance",
        fields: [
          {name: "scheme",  type: "select", inputName: "user[insurance_attributes][scheme]", label: null, options: I18n.t("simple_form.options.insurance.scheme"), prompt: I18n.t("simple_form.include_blanks.insurance.scheme")},
        ],
      },
      [
        {
          key: "2",
          icon: "fas fa-clipboard-list",
          modelName: "insurance",
          fields: [
            {name: "industry",     type: "text",   inputName: "user[insurance_attributes][industry]",     label: null, placeholder: null},
            {name: "company_type", type: "select", inputName: "user[insurance_attributes][company_type]", label: null, options: I18n.t("simple_form.options.insurance.company_type"), prompt: I18n.t("simple_form.include_blanks.insurance.company_type")},
            {name: "company_age",  type: "select", inputName: "user[insurance_attributes][company_age]",  label: null, options: I18n.t("simple_form.options.insurance.company_age"), prompt: I18n.t("simple_form.include_blanks.insurance.company_age")},
          ],
        },
        {
          key: "3",
          icon: "fas fa-clipboard-list",
          modelName: "insurance",
          fields: [
            {name: "employees", type: "number", inputName: "user[insurance_attributes][employees]", label: null, placeholder: null},
          ],
        },
        {
          key: "4",
          icon: "fas fa-clipboard-list",
          modelName: "insurance",
          fields: [
            {name: "cars", type: "number", inputName: "user[insurance_attributes][cars]", label: null, placeholder: null},
          ],
        }
      ],
      {
        key: "5",
        icon: "fas fa-info-circle",
        modelName: "insurance",
        fields: [
          {name: "description", type: "textarea", inputName: "user[insurance_attributes][description]", label: null, placeholder: null},
        ],
      },
      {
        key: "6",
        legend: I18n.t("js.insurance_services_wizard.contacts"),
        icon: "fas fa-user",
        modelName: "user",
        fields: [
          {name: "phone",            type: "text",             inputName: "user[phone]",            autoComplete: "tel",          label: null, placeholder: null},
          {name: "email",            type: "email",            inputName: "user[email]",            autoComplete: "email",        label: null, placeholder: null},
          {name: "terms_of_service", type: "terms_of_service", inputName: "user[terms_of_service]", autoComplete: null,           label: null, placeholder: null},
          {name: "privacy_policy",   type: "privacy_policy",   inputName: "user[privacy_policy]",   autoComplete: null,           label: null, placeholder: null},
        ],
      },
    ]
  }

  getInitialState() {
    return {
      user: {
        phone:            {value: "", error: null},
        email:            {value: "", error: null},
        terms_of_service: {value: false, error: null},
        privacy_policy:   {value: false, error: null},
      },
      insurance: {
        scheme:       {value: "", error: null},
        industry:     {value: "", error: null},
        company_type: {value: "", error: null},
        company_age:  {value: "", error: null},
        employees:    {value: "", error: null},
        cars:         {value: "", error: null},
        description:  {value: "", error: null},
      },
      step: 0,
      loading: false,
    }
  }

  getSteps() {
    return this.steps.map(step => {
      if (!isArray(step)) {
        return step
      }

      switch(this.state.insurance.scheme.value) {
        case 'liability':
        case 'criminal_law':
        case 'expenses':
        case 'property':
          return step[0]
        case 'health':
        case 'pension':
        case 'accident':
        case 'dno':
          return step[1]
        case 'car':
          return step[2]
        default:
          return step[1]
      }
    })
  }

  validateRegistration() {
    trackInteraction()
    return validate({
      user: {
        email: this.state.user.email.value,
        phone: this.state.user.phone.value,
        terms_of_service: this.state.user.terms_of_service.value ? "1" : "0",
        privacy_policy: this.state.user.privacy_policy.value ? "1" : "0",
        insurance_attributes: {
          scheme: this.state.insurance.scheme.value,
          industry: this.state.insurance.industry.value,
          company_type: this.state.insurance.company_type.value,
          company_age: this.state.insurance.company_age.value,
          description: this.state.insurance.description.value,
          employees: this.state.insurance.employees.value,
          cars: this.state.insurance.cars.value,
        }
      },
    })
  }

}
