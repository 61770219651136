import React from "react"
import PropTypes from "prop-types"
import {snakeCase, isEmpty} from "lodash"
import classNames from "classnames"

const AgreementsFormRow = props => {
  const inputId = snakeCase(props.inputName);
  return (
      <div className="form-group">
        <div className="form-check">
          <input
              name={props.inputName}
              type="hidden"
              value="0"
          />
          <input
              id={inputId}
              className={classNames("form-check-input agreements-checkbox", {"is-invalid": !isEmpty(props.error)})}
              name={props.inputName}
              type="checkbox"
              value="1"
              checked={props.value}
              onChange={event => props.onChange(event.target.checked)}
          />
          <label
              className="form-check-label"
              htmlFor={inputId}
              dangerouslySetInnerHTML={{__html: props.label}}
          />
          <div className="invalid-feedback">{props.error}</div>
        </div>
      </div>
  )
}

AgreementsFormRow.propTypes = {
  inputName: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default AgreementsFormRow
