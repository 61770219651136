import React from "react"
import PropTypes from "prop-types"
import AgreementsFormRow from "./agreements"

const tosLink = `
  <a target="_blank" class="accent bold" href="${Routes.static_agreements_path()}">
    ${I18n.t("js.company_registration_terms_of_service_form_row.link")}
  </a>`

const label = I18n.t("js.company_registration_terms_of_service_form_row.label", {
  link: tosLink
})

const TermsOfServiceFormRow = props => {
  return (
      <AgreementsFormRow
          inputName={props.inputName}
          value={props.value}
          error={props.error}
          label={label}
          onChange={props.onChange}
      />
  )
}

TermsOfServiceFormRow.propTypes = {
  type: PropTypes.string,
  inputName: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
}

export default TermsOfServiceFormRow
