import {noop, reduce} from "lodash"

export const validate = (params) => {
  return $.getJSON(Routes.validate_renovation_services_sign_up_path(), params).then(noop, (res) => {
    return Promise.reject(
        reduce(res.responseJSON, (modelErrors, errors, key) => {
          if (key.startsWith("data.")) {
            modelErrors.renovation_services_data[key.replace("data.", "")] = errors[0];
          } else {
            modelErrors.user[key] = errors[0];
          }
          return modelErrors
        }, {user: {}, renovation_services_data: {}}));
  })
}
