import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const EditMode = (props) => (
    <div className="offer-edit">
      <div className="form-row">
        <div className="form-group col-md-6">
          <input
              className={classNames("string form-control", {"is-invalid": props.errors["price_from"]})}
              name="offer[price_from]"
              type="number"
              min="0"
              max="20000000"
              step="0.01"
              value={props.data.price_from}
              disabled={props.disabled}
              onChange={props.onChange}
          />
          <div className="invalid-feedback">{props.errors["price_from"]}</div>
        </div>
        <div className="form-group col-md-6">
          <input
              className={classNames("string form-control", {"is-invalid": props.errors["price_to"]})}
              name="offer[price_to]"
              type="number"
              min="0"
              max="20000000"
              step="0.01"
              value={props.data.price_to}
              disabled={props.disabled}
              onChange={props.onChange}
          />
          <div className="invalid-feedback">{props.errors["price_to"]}</div>
        </div>
      </div>

      <div className="form-group">
          <textarea
              className={classNames("text required form-control", {"is-invalid": props.errors["details"]})}
              name="offer[details]"
              rows="7"
              value={props.data.details}
              disabled={props.disabled}
              onChange={props.onChange}
          />
        <div className="invalid-feedback">{props.errors["details"]}</div>
      </div>

      <div className="form-group">
        <button
            type="button"
            onClick={props.onSubmit}
            disabled={props.disabled}
            className="btn btn-rounded btn-accent btn-sm mr-1" >
          {I18n.t("js.offer_edit_mode.save")}
        </button>
        <button
            type="button"
            onClick={props.onCancel}
            disabled={props.disabled}
            className="btn btn-rounded btn-1 btn-sm">
          {I18n.t("js.offer_edit_mode.cancel")}
        </button>
      </div>
    </div>
)

EditMode.propTypes = {
  data: PropTypes.shape({
    price_from: PropTypes.string,
    price_to: PropTypes.string,
    details: PropTypes.string.isRequired,
  }).isRequired,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default EditMode
