import React from "react"
import PropTypes from "prop-types"
import EditMode from "./edit_mode";
import ViewMode from "./view_mode";

const LinkToCompany = (props) => {
  if (props.slug === "#") {
    return (
        <span>{props.children}</span>
    );
  } else {
    return (
        <a href={Routes.company_path(props.slug)} target="_blank">
          {props.children}
        </a>
    );
  }
}

LinkToCompany.propTypes = {
  slug: PropTypes.string.isRequired,
}

class Offer extends React.Component {
  render() {
    const offer = this.props.offer
    return (
        <div id={`offer-${offer.id}`}
             className={classNames("media offer p-2", this.props.className)}
             onAnimationEnd={this.props.onAnimationEnd}>
          <LinkToCompany slug={offer.company_slug}>
            <img className="d-flex mr-3 img-icon-xl border" src={offer.company_logo}/>
          </LinkToCompany>
          <div className="media-body">
            <h5 className="my-0">
              <LinkToCompany slug={offer.company_slug}>
                {offer.company_name}
              </LinkToCompany>
            </h5>
            <p className="small mt-0">
              {offer.created_at !== offer.updated_at ? (
                  I18n.t("js.offer.updated") + ': ' + offer.updated_at
              ) : (
                  I18n.t("js.offer.posted") + ': ' + offer.created_at
              )}
            </p>
            {this.props.editMode ? (
                <EditMode
                    data={this.props.editModeOffer}
                    errors={this.props.errors}
                    onChange={this.props.onOfferChange}
                    onSubmit={this.props.onEditSubmit}
                    onCancel={this.props.onEditCancel}
                    disabled={this.props.editFormDisabled}
                />
            ) : (
                <ViewMode
                    ability={this.props.ability}
                    details={offer.details}
                    price={offer.price}
                    onEdit={this.props.onEdit}
                    onDelete={this.props.onDelete}
                    onWin={this.props.onWin}
                />
            )}
          </div>
        </div>
    );
  }
}

Offer.propTypes = {
  className: PropTypes.string,
  onAnimationEnd: PropTypes.func,
  ability: PropTypes.shape({
    update: PropTypes.bool.isRequired,
    destroy: PropTypes.bool.isRequired,
    win: PropTypes.bool.isRequired,
  }).isRequired,
  offer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    price_from: PropTypes.string,
    price_to: PropTypes.string,
    price: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
    company_slug: PropTypes.string.isRequired,
    company_logo: PropTypes.string.isRequired,
    company_name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }),
  editModeOffer: PropTypes.shape({
    price_from: PropTypes.string,
    price_to: PropTypes.string,
    details: PropTypes.string,
  }),
  errors: PropTypes.object.isRequired,
  editMode: PropTypes.bool.isRequired,
  editFormDisabled: PropTypes.bool,

  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onWin: PropTypes.func.isRequired,

  onOfferChange: PropTypes.func.isRequired,
  onEditSubmit: PropTypes.func.isRequired,
  onEditCancel: PropTypes.func.isRequired,
}

export default Offer
