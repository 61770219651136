import React from "react"
import PropTypes from "prop-types"
import {snakeCase, isEmpty, map} from "lodash"
import classNames from "classnames"

const SelectFormRow = props => {
  const inputId = snakeCase(props.inputName)
  return (
      <div className="form-group">
        <label className="small bold" htmlFor={inputId}>
          {props.label}
        </label>
        <select
            id={inputId}
            className={classNames("form-control select", props.className, {"is-invalid": !isEmpty(props.error)})}
            name={props.inputName}
            onChange={(event) => {
              ahoy.track("SelectFormRow onChange", {name: props.inputName, value: event.target.value})
              props.onChange(event.target.value)
            }}
            onFocus={() => {
              ahoy.track("SelectFormRow onFocus", {name: props.inputName})
            }}
            defaultValue=""
        >
          <option value="" disabled hidden>{props.prompt}</option>
          {map(props.options, (label, value) =>
              <option key={value} value={value}>{label}</option>
          )}
        </select>
        <div className="invalid-feedback">{props.error}</div>
      </div>
  )
}

SelectFormRow.propTypes = {
  inputName: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  label: PropTypes.string,
  prompt: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.objectOf(PropTypes.string),
}

export default SelectFormRow
